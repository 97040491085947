@tailwind base;
@tailwind components;
@tailwind utilities;
@media only screen and (max-width: 600px){
    .h-screen {
        margin-top: 10rem!important;
    }
}

body {
    background-image: url('../public/bg.png');
}


